import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import Register from "./globalRegister";
import "./assets/icon/iconfont.css";
import "normalize.css/normalize.css";
import "vant/lib/index.css";
import "video.js/dist/video-js.css";

createApp(App).use(store).use(router).use(Vant).use(Register).mount("#app");
