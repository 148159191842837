import api from "./api";
import http from "./utils/request";

export default {
  install(app) {
    //挂载全局对象
    app.config.globalProperties.$HTTP = http;
    app.config.globalProperties.$API = api;
  },
};
