import config from "@/config";
import http from "@/utils/request";

export default {
  token: {
    url: `${config.API_URL}/login`,
    name: "登录获取TOKEN",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
  previewLogin: {
    url: `${config.API_URL}/previewLogin`,
    name: "预览登录，获取TOKEN",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
};
