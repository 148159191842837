<template>
  <div class="main-container">
    <div
      class="nav-container"
      :class="navBackgroundColor == 'transparent' ? '' : 'nav-shadow '"
      :style="{ backgroundColor: navBackgroundColor }"
    >
      <div class="nav-header">
        <a class="nav-logo-link" :style="{ width: menuOpened ? '0px' : '66px' }" target="_self">
          <img
            v-show="!menuOpened"
            style="width: 56px; height: 26px"
            src="@/assets/image/nav_logo.png"
            alt=""
          />
        </a>
        <!-- <div class="nav-search">
           <van-icon class="search-icon" name="search" color="#ffffff" size="28" />
        </div> -->
        <div class="nav-more" @click="navMoreClick">
          <span class="iconfont" :class="menuOpened ? 'icon-cross' : 'icon-wap-nav'"></span>
        </div>
      </div>
      <div v-show="!menuOpened" class="nav-search">
        <van-form>
          <van-search
            v-model="searchText"
            @search="onSearch"
            @cancel="onCancel"
            style="background-color: transparent"
            placeholder="请输入搜索关键词"
          />
        </van-form>
      </div>
      <div class="nav-menu" v-show="menuOpened">
        <a class="nav-menu-list-item" @click.prevent="clickNavMenu(1)">首页</a>
        <a class="nav-menu-list-item" @click.prevent="clickNavMenu(2)">公司介绍</a>
        <a class="nav-menu-list-item" @click.prevent="clickNavMenu(3)">热招职位</a>
        <a class="nav-menu-list-item" @click.prevent="clickNavMenu(4)">产业规模</a>
      </div>
    </div>
    <div ref="section1" class="top-container" style="width: 100%">
      <div class="img_container">
        <img
          :src="company.imagePath"
          alt=""
          :title="company.companyName"
          la="la"
          needthumb="true"
        />
      </div>
      <div class="title-container">
        <div class="p-item">
          <p class="e_text-3">{{ company.companyName }}</p>
          <hr class="e_line-5" />
          <p class="e_text-4">“仕”为知己 “净”待您来</p>
        </div>
      </div>
    </div>
    <div class="content-container">
      <!-- <van-swipe ref="section1" :autoplay="3000" lazy-render style="width: 100%">
        <van-swipe-item v-for="image in images" :key="image">
          <img style="width: 100%" :src="image" />
        </van-swipe-item>
      </van-swipe> -->
      <div class="section" ref="section2">
        <p class="text-h2">关于{{ company.companyName }}</p>
        <div class="section-content">
          <p v-html="company.introduce"></p>
        </div>
      </div>
      <div class="video-container">
        <!-- <video
          ref="videoPlayer"
          class="video-js vjs-default-skin video-element"
          controls
          autoplay
          width="100%"
        >
          <source src="http://s74m58ov2.hd-bkt.clouddn.com/company_intro.mp4" type="video/mp4" />
        </video> -->

        <video controls autoplay muted playsinline class="video-element">
          <source src="http://s74m58ov2.hd-bkt.clouddn.com/company_intro.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="section" ref="section3">
        <p class="text-h2">热招职位</p>
        <div class="section-content" style="padding: 2px 0px">
          <van-cell
            class="job-item"
            v-for="item in list"
            :key="item.jobInfoId"
            @click="jobClick(item)"
            is-link
          >
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <van-tag type="danger">急</van-tag>
              <span class="custom-title"> {{ item.jobName }}</span>
            </template>
            <template #label>
              <span class="custom-label">
                {{ jobTypeMap[`${item.jobType}`] }} | {{ item.dept }} | {{ item.city }}</span
              >
            </template>
          </van-cell>

          <!-- <van-cell class="job-item" label="全职 | 0000100安徽仕净" @click="jobClick" is-link>
            <template #title>
              <van-tag type="danger">急</van-tag>
              <span class="custom-title"> 操作工（安徽）</span>
            </template>
          </van-cell>

          <van-cell class="job-item" label="全职 | 0000100安徽仕净" @click="jobClick" is-link>
            <template #title>
              <van-tag type="danger">急</van-tag>
              <span class="custom-title"> 操作工（安徽）</span>
            </template>
          </van-cell> -->
        </div>
      </div>
      <div class="section" ref="section4">
        <p class="text-h2">产业规模</p>
        <div class="section-content">
          <van-swipe :autoplay="3000" lazy-render style="width: 100%">
            <van-swipe-item class="sd-swipe-item" v-for="image in images" :key="image">
              <img style="width: 100%; display: block" :src="image.url" />
              <div class="title-container">
                <div class="p-item">
                  <p class="e_text-3">{{ image.title }}</p>
                  <p class="e_text-4">{{ image.desc }}</p>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <div class="footer">
        <img
          style="width: 66px; height: 32px"
          src="@/assets/image/nav_logo.png"
          alt=""
          @click="onTestClick"
        />
        <div style="margin-top: 14px">Copyright©2023 安徽仕净光能科技有限公司</div>
        <div style="margin-top: 6px">
          <!-- <div>
            <img
              width="16"
              height="16"
              src="https://static-ats.mokahr.com/recruitment-mobile-client/images/publicSecurityIcon.png"
              style="margin-right: 6px"
            /><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024479"
              target="_blank"
              >京公网安备 11010802024479号</a
            >
          </div> -->
          <a
            style="display: inline-block; margin-top: 6px"
            href="https://beian.miit.gov.cn"
            target="_blank"
            >皖ICP备2023027715号-1</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import videojs from "video.js";
const navHeight = 120;
export default {
  name: "HomeView",
  data() {
    return {
      menuOpened: false,
      windowScrollY: 0,
      listHeight: 0,
      searchText: null,
      player: null,
      observer: null,
      jobTypeMap: {
        1: "全职",
        2: "兼职",
        3: "实习",
      },
      company: {
        companyName: "仕净新能源",
        imagePath: "http://s74m58ov2.hd-bkt.clouddn.com/company_scale_1.jpg",
        introduce:
          "<p>安徽仕净光能科技有限公司，简称仕净光能，公司成立于2023年1月，位于宁国经济技术开发区河沥园区兴盛路，注册资金50000万元，是苏州仕净科技有限公司全资子公司。</p><p>仕净科技(301030)成立于2005年4月，总部位于江苏省苏州市相城区。目前全国布局子(分)公司20余家，公司总人数1800余人。</p><p>主要业务板块涵盖环保装备制造、工业流程CCUS（碳捕集、利用、封存）、高效太阳能光伏产品的研发制造等领域。其中太阳能光伏行业废气治理占国内75%以上的市场份额，废水治理和纯水业务占国内40%以上市场份额。受益于光伏行业持续扩产,公司业绩快速增长，年度订单已达110亿元以上。</p><p>仕净科技产业园由苏州仕净科技投资建设，位于安徽省宁国经济技术开发区河沥园区，项目总占地面积约1300亩,项目总投资约240亿元。一期24GW电池片项目即将投产；形成从光伏上游材料到最终产品应用的连续生产制造,主要包括硅片、电池片、光伏组件、储能制造4个环节，最终打造光伏垂直一体化产业链，同时打造固碳全国总部项目。</p>",
      },
      images: [
        {
          url: "http://s74m58ov2.hd-bkt.clouddn.com/company_scale_3.jpg",
          title: "一期项目",
          desc: "项目占地面积约1300亩，总投资75亿元，预计实现年销售收入约130亿元,税收约5亿元,用工人数约1800人。",
        },
        {
          url: "http://s74m58ov2.hd-bkt.clouddn.com/company_scale_2.jpg",
          title: "二期项目",
          desc: "     ",
        },
      ],
      list: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getCompanyIntroduce();
    this.onLoad();
    // this.player = videojs(this.$refs.videoPlayer, {
    //   loop: true,
    //   autoplay: true,
    //   playsinline: true,
    // });

    // this.observer = new IntersectionObserver(
    //   entries => {
    //     if (entries[0].isIntersecting) {
    //       console.log("play");
    //       this.player.play();
    //     } else {
    //       console.log("pause");
    //       this.player.pause();
    //     }
    //   },
    //   {
    //     threshold: 0.1, // 当视频元素10%可见时开始观察
    //   }
    // );

    // this.observer.observe(this.$refs.videoPlayer);
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    // if (this.observer) {
    //   this.observer.unobserve(this.$refs.videoPlayer);
    // }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  computed: {
    navBackgroundColor() {
      return this.windowScrollY >= navHeight || this.menuOpened ? "white" : "transparent"; //#0093ff
    },
  },

  methods: {
    handleScroll() {
      this.windowScrollY = window.scrollY;
    },
    navMoreClick() {
      this.menuOpened = !this.menuOpened;
    },
    async getCompanyIntroduce() {
      try {
        let res = await this.$API.company.detail.post({});
        if (res.code == 200 && res.success == true) {
          if (res.result.companyName) this.company.companyName = res.result.companyName;
          if (res.result.imagePath) this.company.imagePath = res.result.imagePath;
          if (res.result.introduce) this.company.introduce = res.result.introduce;
        }
      } catch (err) {
        console.log("getCompanyIntroduce:", err);
      }
    },
    async onLoad() {
      const params = {
        page: 1,
        pageSize: 5,
      };

      try {
        let res = await this.$API.job.list.post(params);
        if (res.code == 200 && res.success == true) {
          this.list = res.result.list;
        }
      } catch (err) {
        console.log("onLoad:", err);
      }
    },
    clickNavMenu(index) {
      this.menuOpened = false;
      let scrollTo = 0;
      switch (index) {
        case 1:
          scrollTo = 0;
          break;
        case 2:
          scrollTo = this.$refs.section2.offsetTop - navHeight;
          break;
        case 3:
          scrollTo = this.$refs.section3.offsetTop - navHeight;
          break;
        case 4:
          scrollTo = this.$refs.section4.offsetTop - navHeight;
          break;
        default:
          scrollTo = 0;
      }
      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
      // this.$refs.section2.scrollIntoView({ behavior: "smooth" });
    },
    onSearch() {
      const query = {};
      if (this.searchText && this.searchText.trim()) {
        query["keyword"] = this.searchText.trim();
      }
      this.$router.push({ path: "/jobs", query });
    },
    onCancel() {},
    jobClick(item) {
      const query = { keyword: item.jobName };
      this.$router.push({ path: "/jobs", query });
    },
    async onTestClick() {
      // this.$router.push({ path: "/about" });
    },
  },
};
</script>

<style scoped>
.video-container {
  width: 100%; /* 确保父容器宽度为100% */
}

.video-element {
  width: 100%; /* 视频元素宽度为100% */
  height: calc(100vw * 221 / 390);
}

.text-h2 {
  font-weight: bold;
  font-size: 24px;
  color: rgb(0, 0, 0);
  text-align: center;
}

.main-container {
  width: 100%;
  background-color: white;
}

.nav-container {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0px 16px;
  color: #141933;
  background-color: transparent;
  transition: background-color 0.3s;
}

.nav-shadow {
  box-shadow: 0px 6px 6px 0px lightgray;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;
  min-height: 40px;
  width: 100%;
  padding: 10px 0px;
}

.nav-logo-link {
  width: 66px;
  height: 32px;
  display: inline-block;
}

.nav-more {
  display: inline-block;
}

/* .nav-search {
  display: inline-block;
  width: 100%;
  padding: 0px 10px;
} */
/* .nav-search .search-icon {
  font-weight: bold;
  float: right;
} */

.nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  transition: height 0.6s;
  padding-bottom: 12px;
}

.nav-menu-list-item {
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #141933;
  padding: 8px 0px;
  margin: 3px 0px;
}

.nav-menu a:hover,
.nav-menu a:visited,
.nav-menu a:link,
.nav-menu a:active {
  color: #141933;
}

@media (min-width: 1000px) {
  .content-container {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.section {
  margin: 10px 0px;
  padding: 0px 0px;
  color: #474c66;
}

.top-container {
  position: relative;
}
.img_container {
  position: relative;
}

.img_container img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.title-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  position: absolute;
  top: 34%;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.p-item {
  position: relative;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
}

.e_text-3 {
  font-size: 26px;
  color: rgba(255, 255, 255, 1);
  line-height: 1;
  font-weight: bold;
  text-align: center;
}

.e_line-5 {
  border-top-color: rgba(255, 255, 255, 1);
  border-top-width: 3px;
  border-top-style: solid;
  max-width: 60px;
}
.p_item hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
}

.e_text-4 {
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 1;
  text-align: center;
}

.section-content p {
  text-indent: 2em;
  line-height: 1.5em;
  padding: 0px 16px;
  margin: 0px 0px;
}

.section-content .job-item {
  margin: 3% 4%;
  width: 92%;
  border-radius: 6px;
  box-shadow: 1px 1px 6px 0px lightgray;
}

.custom-label {
  font-size: 14px;
  margin: 6px 0px;
  display: block;
}

.sd-swipe-item {
  position: relative;
}

.sd-swipe-item .title-container {
  top: auto;
  bottom: 0px;
  background: rgba(5, 128, 183, 0.7);
}

.sd-swipe-item .title-container .e_text-3 {
  margin-left: 16px;
  text-align: left;
  text-indent: 0px;
  font-size: 24px;
  padding: 0px 0px;
}

.sd-swipe-item .title-container .e_text-4 {
  margin-left: 16px;
  text-align: left;
  text-indent: 0px;
  padding: 0px 0px;
}
/* .sd-swipe-item .title-container .e_text-3 {
  margin-left: 16px;
} */

.footer {
  margin: 40px 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 1em;
  color: rgb(20, 25, 51);
  text-align: center;
}

.footer a:hover,
.footer a:visited,
.footer a:link,
.footer a:active {
  color: rgb(20, 25, 51);
}
</style>
