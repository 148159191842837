const DEFAULT_CONFIG = {
  //标题
  APP_NAME: process.env.VUE_APP_TITLE,

  //版本号
  APP_VER: "1.0.0",

  //内核版本号
  CORE_VER: "1.0.0",

  //接口地址
  API_URL:
    process.env.NODE_ENV === "development" && process.env.VUE_APP_PROXY === "true"
      ? "/api"
      : process.env.VUE_APP_API_BASEURL + "/api/admin",

  //请求超时
  TIMEOUT: 30000,

  //TokenName
  TOKEN_NAME: "Authorization",

  //Token前缀，注意最后有个空格，如不需要需设置空字符串
  TOKEN_PREFIX: "Bearer ",

  //追加其他头
  HEADERS: {},

  //请求是否开启缓存
  REQUEST_CACHE: false,

  //语言
  LANG: "zh-cn",

  //主题颜色
  COLOR: "",

  //是否加密localStorage, 为空不加密，可填写AES(模式ECB,移位Pkcs7)加密
  LS_ENCRYPTION: "",

  //localStorageAES加密秘钥，位数建议填写8的倍数
  LS_ENCRYPTION_key: "2XNN4K8LC0ELVWN4",
};

// 如果生产模式，就合并动态的APP_CONFIG
// public/config.js
// if(process.env.NODE_ENV === 'production'){
// 	debugger;
// 	Object.assign(DEFAULT_CONFIG, APP_CONFIG)
// }

export default DEFAULT_CONFIG;
