import axios from "axios";
import { showToast } from "vant";
import sysConfig from "@/config";
import tool from "@/utils/tool";
// import router from "@/router";

axios.defaults.baseURL = "";

axios.defaults.timeout = sysConfig.TIMEOUT;

// HTTP request 拦截器
axios.interceptors.request.use(
  config => {
    let token = tool.cookie.get("TOKEN");
    if (token) {
      config.headers[sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + token;
    }
    if (!sysConfig.REQUEST_CACHE && config.method == "get") {
      config.params = config.params || {};
      config.params["_"] = new Date().getTime();
    }
    Object.assign(config.headers, sysConfig.HEADERS);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// HTTP response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status == 404) {
        showToast("正在请求不存在的服务器记录！");
      } else if (error.response.status == 400) {
        showToast("服务器错误！");
      } else if (error.response.status == 500) {
        showToast("服务器错误！");
      } else if (error.response.status == 401) {
        showToast("当前用户已被登出或无权限访问当前资源");
      } else if (error.response.status == 405) {
        showToast("登录已过期，请重新登录");
      } else {
        showToast("服务器错误！");
      }
    } else {
      showToast("请求服务器无响应！");
    }

    return Promise.reject(error.response);
  }
);

var http = {
  /** get 请求
   * @param  {string} url 接口地址
   * @param  {object} params 请求参数
   * @param  {object} config 参数
   */
  get: function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: url,
        params: params,
        ...config,
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** post 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  post: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** put 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  put: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** patch 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  patch: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** delete 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  delete: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: url,
        data: data,
        ...config,
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** jsonp 请求
   * @param  {string} url 接口地址
   * @param  {string} name JSONP回调函数名称
   */
  jsonp: function (url, name = "jsonp") {
    return new Promise(resolve => {
      var script = document.createElement("script");
      var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`;
      script.id = _id;
      script.type = "text/javascript";
      script.src = url;
      window[name] = response => {
        resolve(response);
        document.getElementsByTagName("head")[0].removeChild(script);
        try {
          delete window[name];
        } catch (e) {
          window[name] = undefined;
        }
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  },
};

export default http;
