// import { createStore } from "vuex";

// export default createStore({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });

import { reactive } from "vue";

export const store = reactive({
  uploadCount: 0,
  deliverCount: 0,
  incrementUCount() {
    this.uploadCount++;
  },
  incrementDCount() {
    this.deliverCount++;
  },
});
