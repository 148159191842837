import config from "@/config";
import http from "@/utils/request";

export default {
  detail: {
    url: `${config.API_URL}/company/getCompanyById`,
    name: "获取公司信息",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
};
