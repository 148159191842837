import config from "@/config";
import http from "@/utils/request";

export default {
  list: {
    url: `${config.API_URL}/recruit/queryJobInfoList`,
    name: "招聘职位列表",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
  detail: {
    url: `${config.API_URL}/recruit/queryJobInfo`,
    name: "招聘职位详情",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
  addressList: {
    url: `${config.API_URL}/recruit/queryJobAddressList`,
    name: "招聘职位地址",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
  categories: {
    url: `${config.API_URL}/enumTable/getEnumTableByType`,
    name: "招聘职位类别",
    post: async function (data = { type: 1 }) {
      return await http.post(this.url, data);
    },
  },
  saveSeeker: {
    url: `${config.API_URL}/recruit/saveSeeker`,
    name: "上传求职者信息",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    },
  },
};
